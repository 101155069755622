<template lang="pug">
v-container.pa-0(fluid  ) 
  component( v-for='{componente, objeto}, i of secciones' :key='i' :is='componente' :objeto='objeto')
</template>
<script>

export default {
  computed: {
    secciones(){
      return [
      {componente: 'encabezado', objeto: null},
      {componente: 'quienes_somos', objeto: null},
      // {componente: 'servicios', objeto: null},

      // {componente: 'arbitraje', objeto: null},
      // {componente: 'consejo', objeto: this.consejo_superior_de_arbitraje},
      // {componente: 'consejo', objeto: this.consejo_de_etica},
      // {componente: 'consejo', objeto: this.secretaria_general},
      // {componente: 'convenio', objeto: null},

      // {componente: 'contacto', objeto: null},
    ]}
  },
  components: {
    encabezado: ()=>import('./encabezado'),
    quienes_somos: ()=>import('./quienes_somos'),
    arbitraje: ()=>import('./arbitraje'),
    consejo: ()=>import('./consejo'),
    contacto: ()=>import('./contacto'),
    servicios: ()=>import('./servicios'),
    convenio: ()=>import('./convenio')

  }
}
</script>

